import {Button, Dropdown, Menu, MenuItem} from "../shoelace";
import React, {FC} from "react";
import clsx from "clsx";
import style from "./ProvinceDropdown.module.scss";
import {isNotEmpty} from "../../utils/validation";

const supportedCountries = ['US'] as const;
type Country = typeof supportedCountries[number];
export const isSupportedCountry = (country: string) =>
    supportedCountries.includes(country as Country);
export const asSupportedCountry = (country: string) =>
    country as Country;

const provinceNames = {"US" :[
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
    'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas',
    'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
    'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
    'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
    'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah',
    'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
  ]};

export const isValidProvinceValue = (country: string, province: string) =>
    isSupportedCountry(country) ?
        provinceNames[country as Country].includes(province) :
        isNotEmpty(province);

interface Props {
  country: Country
  province: string
  setProvince: (province: string) => void
}

const ProvinceDropdown: FC<Props> = ({country, province, setProvince}) => {
  return (
      <Dropdown className={clsx('form-input', 'light', style.province)}>
        <Button slot="trigger" caret className="province-button icon">
          {province}
        </Button>
        <Menu>
          {provinceNames[country]
              .sort((a, b) =>
                  a.toLowerCase().localeCompare(b.toLowerCase())
              )
              .map((prov) => (
                  <MenuItem
                      className="state-menu-item"
                      onClick={() => setProvince(prov)}
                      key={prov}
                  >
                    {prov}
                  </MenuItem>
              ))}
        </Menu>
      </Dropdown>
  );
}

export default ProvinceDropdown

// Checkout a customer by calling one endpoint per plan
import {KeyedPromiseRequests, PostCheckoutData} from "./types";
import {chainPromisesAndGetResponses} from "./chainPromises";
import {apiCheckout} from "./index";
import {apiCheckoutPortalUser} from "./portalEndpoints";

export const checkoutIndividualPlans = (checkoutRequests: KeyedPromiseRequests<PostCheckoutData>,
                                        customerId: string,
                                        successCallback: (key: string) => void) => {
  return chainPromisesAndGetResponses(
      checkoutRequests.map(request => ({
        key: request.key,
        func: () => apiCheckout(customerId, request.body)
      })),
      successCallback
  );
}
// Checkout a portal user by calling one endpoint per plan
export const checkoutPortalUserIndividualPlans = (checkoutRequests: KeyedPromiseRequests<PostCheckoutData>,
                                                  successCallback: (key: string) => void) => {
  return chainPromisesAndGetResponses(
      checkoutRequests.map(request => ({
        key: request.key,
        func: () => apiCheckoutPortalUser(request.body)
      })),
      successCallback
  );
}

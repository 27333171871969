import {Shuttle} from '../../external/shuttle-1.2.X';
import React from "react";
import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import {showAlert} from "../../store/shared/alert/actions";
import {bfApi} from "../../api";
import {getAlertFromApiErrorResponse} from "../../utils/utils";
import {ShuttleAuthCaptureRequest, ShuttlePreAuthRequest, ShuttlePreAuthResponse} from "../../api/types";
import {AlertType, ShowAlertActionPayload} from "../../store/shared/alert/types";
import {debounce} from "ts-debounce";

import "./ShuttlePaymentForm.scss"
import {Cart} from "../../store/user/cart/types";

const mapState = (state: RootState) => ({
    currency: state.main.user.plan.currency,
    userDetails: state.main.user.userDetails.details,
    cart: state.main.user.cart.cart,
    bfAccountId: state.main.user.userDetails.bfAccountId,
    bfPublicToken: state.main.storefront.storefront.publicToken
});

const mapDispatch = {
    showAlert: showAlert,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    onSuccessfulSubmit: (id: string) => void
}

interface State {
    shuttleSignature: string;
    shuttleOptions: string;
    shuttleInstance: string;
    isLoading: boolean;
    hasError: boolean;
}

function getUniqueApiConfigurationId(cart: Cart) {
    //Ideally this should not be needed because we should be able to take payment for multiple Shuttle configurations.

    let confId = undefined;
    for (let i = 0; i < cart.length; i++) {
        let thisConfId = cart[i]?.plan?.metadata?.apiConfigurationId;
        if (i === 0) {
            confId = thisConfId;
        } else if (confId !== thisConfId) {
            throw Error("Invalid cart - multiple payment method configurations.");
        }
    }
    return confId;
}

class ShuttlePaymentForm extends React.Component<Props, State> {
    private readonly handleShuttleMessage: EventListener;

    constructor(props: Props) {
        super(props);
        this.state = {
            shuttleSignature: "",
            shuttleOptions: "",
            shuttleInstance: "",
            isLoading: true,
            hasError: false
        };
        this.handleShuttleMessage = (ev: Event) => this.shuttleMessage(ev as MessageEvent);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.handleShuttleMessage)
    }

    componentDidMount() {
        window.addEventListener("message", this.handleShuttleMessage);

        const options = {
            account: {
                crm_key: this.props.bfAccountId,
                name: (this.props.userDetails.email || (this.props.userDetails.firstName + " " + this.props.userDetails.lastName)) + " " + this.props.bfAccountId.substring(0, 12),
                first_name: this.props.userDetails.firstName,
                last_name: this.props.userDetails.lastName,
            }
        }
        const shuttlePreAuth: ShuttlePreAuthRequest = {
            billForwardPublicToken: this.props.bfPublicToken,
            gateway: "Shuttle",
            '@type': "ShuttlePreAuthRequest",
            configurationID: getUniqueApiConfigurationId(this.props.cart),
            signatureBody: JSON.stringify(options)
        };

        bfApi.post('v1/tokenization/pre-auth', {
            headers: {
                "Authorization": "Bearer " + this.props.bfPublicToken,
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(shuttlePreAuth)
        }).then(response => {
            response.json().then(output => {
                const shuttleResponse = output.results[0] as ShuttlePreAuthResponse;
                this.setState({
                    shuttleOptions: btoa(JSON.stringify(JSON.parse(shuttleResponse.hydratedOptions))),
                    shuttleSignature: shuttleResponse.signature,
                    shuttleInstance: shuttleResponse.instanceKey,
                }, () => {
                    Shuttle.bind();
                    this.setState({isLoading: false});
                });
            });
        }, error => {
            this.handleError(getAlertFromApiErrorResponse(error, 'There was a problem preparing your payment method.'));
        });
    }

    private handleError: (error: ShowAlertActionPayload) => void =
        debounce((error: ShowAlertActionPayload) => {
            this.props.showAlert(error);
            this.setState({
                hasError: true,
                isLoading: false
            });
        }, 2000, {isImmediate: true});

    private shuttleMessage(ev: MessageEvent) {
        if (!ev.origin.endsWith(".withbolt.com")) {
            return;
        }

        const data = (ev.data instanceof Object) ? ev.data : JSON.parse(ev.data);
        if (data.message === "SELECT_TOKEN_CLOSE" && data.cancelled) {
            const err: ShowAlertActionPayload = {
                message: "Card capture was cancelled" + (data.error ? ": " + data.error : ""),
                type: AlertType.Error
            };
            this.handleError(err);
        } else if (data.message === "SELECT_TOKEN_CLOSE" && data.success) {
            const shuttleCapture: ShuttleAuthCaptureRequest = {
                "@type": "ShuttleAuthCaptureRequest",
                gateway: "Shuttle",
                shuttlePaymentMethod: data.payment_method.id,
                shuttleInstanceId: this.state.shuttleInstance,
                accountID: this.props.bfAccountId,
                defaultPaymentMethod: true
            };
            bfApi.post("v1/tokenization/auth-capture", {
                headers: {
                    "Authorization": "Bearer " + this.props.bfPublicToken,
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(shuttleCapture)
            }).then(results => {
                results.json()
                    .then(json => this.props.onSuccessfulSubmit(json.results[0].id));
                this.setState({isLoading: false});
            }, error => {
                this.handleError(getAlertFromApiErrorResponse(error, 'There was a problem processing your payment method.'));
            });
        }
    }

    render() {
        return (
            <div className={"shuttle"}>
                <button className={"button button--default button--medium shuttle-pay-button submit"} data-shuttle-token={this.state.shuttleOptions} data-shuttle-signature={this.state.shuttleSignature}>Provide payment details</button>
            </div>
        )
    }
}

export default connector(ShuttlePaymentForm);
